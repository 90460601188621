import { FC, useEffect } from "react";
import { useAppStateContext } from "../context/state-context-provider";
import { UpdateUserWithServerResponse } from "../../helper/general/methods";
import { UserRegistration } from "../../core/database/user/user-registration";
import { UserAuthentication } from "../../core/database/user/user-authentication";
import { UserPasswordRecover } from "../../core/database/user/user-password-recover";
import { UserProfileEdit } from "../../core/database/user/user-profile-edit";
import { ProjectEdit } from "../../core/database/project/project-edit";
import { ProjectCreate } from "../../core/database/project/project-create";
import { ProjectDelete } from "../../core/database/project/project-delete";
import { ProjectDownload } from "../../core/database/project/project-download";
import { ProjectShare } from "../../core/database/project/project-share";
import { ModelRetrieve } from "../../core/database/file/model-retrieve";
import { FileDownload } from "../../core/database/file/file-download";
import { FileDelete } from "../../core/database/file/file-delete";
import { FileUpload } from "../../core/database/file/file-upload";
import { FolderCreate } from "../../core/database/folder/folder-create";
import { FolderDelete } from "../../core/database/folder/folder-delete";
import { FolderDownload } from "../../core/database/folder/folder-download";
import { FolderEdit } from "../../core/database/folder/folder-edit";
import { ReportDelete } from "../../core/database/report/report-delete";
import { ReportDownload } from "../../core/database/report/report-download";
import { ReportRetrieve } from "../../core/database/report/report-retrieve";
import { ReportDescriptionRetrieve } from "../../core/database/report/report-description-retrieve";
import { ReportDescriptionUpload } from "../../core/database/report/report-description-upload";
import { ReportElementNoteUpload } from "../../core/database/report/report-elemment-note-upload";
import {
  CreateReportFromServerResponse,
  UpdateReportWithServerResponse,
} from "../../helper/report/methods";
import { ReportElementNoteEdit } from "../../core/database/report/report-elemment-note-edit";
import { ReportElementNoteDelete } from "../../core/database/report/report-element-note.delete";
import { UserRetrieve } from "../../core/database/user/user-retrieve";
import { UserLogOut } from "../../core/database/user/user-logout";
import { ServerResponseInterface } from "../interfaces/server/server-response";

export const CoreHandler: FC = () => {
  const [state, dispatch] = useAppStateContext();

  useEffect(() => {
    if (state.data) {
      const postCall = async (operation: any) => {
        dispatch({
          type: "RESPONSE",
          response: "WAIT",
        });

        let serverResponse;
        try {
          switch (operation) {
            // USER SECTION
            case "UserRegistrationInterface":
              serverResponse = await UserRegistration.Register(state);
              break;
            case "UserAuthenticationWebInterface":
              serverResponse = await UserAuthentication.Authenticate(state);
              break;
            case "UserProfileEditInterface":
              serverResponse = await UserProfileEdit.Edit(state);
              break;
            case "UserPasswordRecoverInterface":
              serverResponse = await UserPasswordRecover.Recover(state);
              break;
            case "UserRetrieveInterface":
              serverResponse = await UserRetrieve.Retrieve(state);
              break;
            case "UserLogOutInterface":
              serverResponse = await UserLogOut.LogOut(state);
              break;
            // PROJECT SECTION
            case "ProjectInterface":
              serverResponse = await ProjectCreate.Create(state);
              break;
            case "ProjectDeleteInterface":
              serverResponse = await ProjectDelete.Delete(state);
              break;
            case "ProjectDownloadInterface":
              serverResponse = await ProjectDownload.Download(state);
              break;
            case "ProjectShareInterface":
              serverResponse = await ProjectShare.Share(state);
              break;
            case "ProjectEditInterface":
              serverResponse = await ProjectEdit.Edit(state);
              break;
            // FOLDER SECTION
            case "FolderCreateInterface":
              serverResponse = await FolderCreate.Create(state);
              break;
            case "FolderDeleteInterface":
              serverResponse = await FolderDelete.Delete(state);
              break;
            case "FolderEditInterface":
              serverResponse = await FolderEdit.Edit(state);
              break;
            case "FolderDownloadInterface":
              serverResponse = await FolderDownload.Download(state);
              break;
            // FILE AND MODEL SECTION
            case "FileUploadInterface":
            case "ModelUploadInterface":
              serverResponse = await FileUpload.Upload(state);
              break;
            case "FileDeleteInterface":
              serverResponse = await FileDelete.Delete(state);
              break;
            case "FileDownloadInterface":
              serverResponse = await FileDownload.Download(state);
              break;
            case "ModelRetrieveInterface":
              serverResponse = await ModelRetrieve.Retrieve(state);
              break;
            // REPORT SECTION
            case "ReportDeleteInterface":
              serverResponse = await ReportDelete.Delete(state);
              break;
            case "ReportDownloadInterface":
              serverResponse = await ReportDownload.Download(state);
              break;
            case "ReportRetrieveInterface":
              serverResponse = await ReportRetrieve.Retrieve(state);
              break;
            case "ReportDescriptionRetrieveInterface":
              serverResponse = await ReportDescriptionRetrieve.Retrieve(state);
              break;
            case "ReportDescriptionUploadInterface":
              serverResponse = await ReportDescriptionUpload.Upload(state);
              break;
            case "ReportElementNoteUploadInterface":
              serverResponse = await ReportElementNoteUpload.Upload(state);
              break;
            case "ReportElementNoteEditInterface":
              serverResponse = await ReportElementNoteEdit.Edit(state);
              break;
            case "ReportElementNoteDeleteInterface":
              serverResponse = await ReportElementNoteDelete.Delete(state);
              break;
            default:
              throw new Error("Unknown operation type");
          }

          dispatch({
            type: "CLEAR_DATA",
          });

          if (typeof serverResponse === "string") {
            dispatch({
              type: "RESPONSE",
              response: serverResponse,
            });
          } else if (
            serverResponse != null &&
            typeof serverResponse === "object"
          ) {
            const serverResponseObject =
              serverResponse as ServerResponseInterface;

            if (serverResponseObject.status === "error") {
              {
                dispatch({
                  type: "RESPONSE",
                  response: serverResponseObject.message,
                });
              }
            } else if (operation === "ModelRetrieveInterface") {
              dispatch({
                type: "OPEN_MODEL",
                payload: serverResponse,
                response: "",
              });
            } else if (operation === "ReportRetrieveInterface") {
              dispatch({
                type: "OPEN_REPORT",
                payload: CreateReportFromServerResponse(serverResponseObject),
                response: "",
              });
            } else if (operation === "ReportDescriptionRetrieveInterface") {
              dispatch({
                type: "OPEN_REPORT_DESCRIPTION",
                payload: serverResponseObject.data!,
              });
            } else if (
              operation === "ReportElementNoteUploadInterface" ||
              operation === "ReportElementNoteDeleteInterface" ||
              operation === "ReportElementNoteEditInterface"
            ) {
              const updatedReport = UpdateReportWithServerResponse(
                state.openReport!,
                serverResponseObject.data!
              );
              dispatch({
                type: "OPEN_REPORT",
                payload: updatedReport,
                response: "",
              });
            } else {
              UpdateUserWithServerResponse(
                serverResponseObject,
                dispatch,
                state.user!
              );
            }
          }
        } catch (error) {
          dispatch({
            type: "RESPONSE",
            response:
              (error as Error).message ||
              "An unexpected error occurred while processing your request.",
          });
        }
      };

      postCall(state.data.type);
    }
  }, [state.data]);

  return <></>;
};
