import { FC, useState, useEffect, Dispatch } from "react";
import { FormatDateBE } from "../../../helper/general/methods";
import { HandleInputChange } from "../../../helper/ui/methods";
import { UserRegistrationInterface } from "../../../middleware/interfaces/user/user-registration-interface";

interface userRegistrationFormProps {
  reveal: boolean;
  setRegistrationFormReveal: Dispatch<any>;
  dispatch: Dispatch<any>;
}

// ...

const UserRegistrationForm: FC<userRegistrationFormProps> = ({
  reveal,
  setRegistrationFormReveal,
  dispatch,
}) => {
  const [submit, setSubmit] = useState(false);
  const [registrationData, setRegistrationData] =
    useState<UserRegistrationInterface>({
      userName: "",
      password: "",
      confirmPassword: "",
      email: "",
      company: "",
      registrationDate: null,
      type: "UserRegistrationInterface",
    });

  useEffect(() => {
    if (submit) {
      registrationData.registrationDate = FormatDateBE(new Date());
      dispatch({
        type: "REGISTER",
        payload: registrationData,
      });
    }
    setSubmit(false);
    setRegistrationFormReveal(false);
  }, [submit]);

  if (reveal) {
    return (
      <div id="registrationWrapper">
        <form id="registrationForm">
          <span>ACCOUNT INFO:</span>
          <br />
          <input
            type="text"
            name="userName"
            placeholder="UserName"
            onChange={(e) => {
              HandleInputChange(e, setRegistrationData);
            }}
          />
          <input
            type="text"
            name="company"
            placeholder="Company"
            onChange={(e) => {
              HandleInputChange(e, setRegistrationData);
            }}
          />
          <input
            type="text"
            name="email"
            placeholder="Email"
            onChange={(e) => {
              HandleInputChange(e, setRegistrationData);
            }}
          />
          <span>PASSWORD:</span>
          <br />
          <input
            type="password"
            name="password"
            placeholder="Password"
            onChange={(e) => {
              HandleInputChange(e, setRegistrationData);
            }}
          />
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            onChange={(e) => {
              HandleInputChange(e, setRegistrationData);
            }}
          />
          <input
            type="button"
            name="submit"
            value="Submit"
            onClick={() => setSubmit(true)}
          />
        </form>
      </div>
    );
  } else {
    return <></>;
  }
};

export default UserRegistrationForm;
