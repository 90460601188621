import axios from "axios";
import { UserStateInterface } from "../../../middleware/interfaces/user/user-state-interface";
import { FileUploadInterface } from "../../../middleware/interfaces/file/file-upload-interface";

export const FileUpload = {
  Upload: async (state: UserStateInterface) => {
    let serverResponse;
    if (state.data) {
      if (state.data.type === "FileUploadInterface") {
        const fileUploadData: FileUploadInterface = state.data;
        const url = "./php/file/file-uploader-proxy.php";
        await axios
          .post(url, fileUploadData)
          .then((response) => {
            serverResponse = response.data;
          })
          .catch((error) => {
            serverResponse = error.message;
          });
      } else if (state.data.type === "ModelUploadInterface") {
        const modelUploadData: FileUploadInterface = state.data;
        const url = "./php/file/file-uploader-proxy.php";
        await axios
          .post(url, modelUploadData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            serverResponse = response.data;
          })
          .catch((error) => {
            serverResponse = {
              status: "error",
              header: "error",
              message: error.message,
            };
          });
      }
    } else {
      serverResponse = {
        status: "error",
        header: "error",
        message: "Something went wrong! Please contact DigitalCharcoal",
      };
    }

    return serverResponse;
  },
};
