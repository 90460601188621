import { Dispatch } from "react";
import { UserInterface } from "../../middleware/interfaces/user/user-interface";
import { UserStateInterface } from "../../middleware/interfaces/user/user-state-interface";
import {
  FindFolderByID,
  UserLogOut,
  PrepareUploadFile,
} from "../general/methods";
import { NavigateFunction } from "react-router-dom";
import axios from "axios";
import { ServerResponseInterface } from "../../middleware/interfaces/server/server-response";
import { UserRetrieveInterface } from "../../middleware/interfaces/user/user-retrieve-interface";
import { UserLastActionInterface } from "../../middleware/interfaces/user/user-last-action-inteface";

const pingInterval = 30000;
export let intervalId: NodeJS.Timeout | null = null;

export const UpdateUserLastActionDate = (
  user: UserInterface | null,
  dispatch: Dispatch<any>
) => {
  console.log("USER ACTION");
  user!.lastActionDate = new Date();
  const UpdateUserStatus = async () => {
    try {
      const serverResponse: any = await PingServerWithLastActionDate(user!);
      if (
        typeof serverResponse === "string" ||
        serverResponse.header === "afk"
      ) {
        UserLogOut(dispatch);
      }
    } catch (error) {
      console.error("Error updating user status:", error);
      UserLogOut(dispatch);
    }
  };

  if (user) {
    if (!intervalId) {
      intervalId = setInterval(UpdateUserStatus, pingInterval);
    }
  } else {
    UserLogOut(dispatch);
  }
};

export const CheckAuthentication = async (dispatch: Dispatch<any>) => {
  const userRetrieveData: UserRetrieveInterface = {
    type: "UserRetrieveInterface",
  };
  dispatch({ type: "RETRIEVE_USER", payload: userRetrieveData });
};

export const HandleInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setData: Dispatch<any>
) => {
  const { name, value } = e.target;
  setData((prevData: any) => ({
    ...prevData,
    [name]: value,
  }));
};

export const HandleDragOver = (
  event: React.DragEvent<HTMLDivElement>,
  setIsHovered: Dispatch<any>
) => {
  event.preventDefault();
  setIsHovered(true);
};

export const HandleDragLeave = (setIsHovered: Dispatch<any>) => {
  setIsHovered(false);
};

export const HandleDrop = (
  event: React.DragEvent<HTMLDivElement>,
  state: UserStateInterface,
  setIsHovered: Dispatch<any>,
  navigate: NavigateFunction,
  dispatch: Dispatch<any>
) => {
  event.preventDefault();
  setIsHovered(false);
  UpdateUserLastActionDate(state.user!, dispatch);
  const uploadFile = event.dataTransfer.files?.[0];
  if (uploadFile) {
    PrepareUploadFile(uploadFile, state.user!, navigate, dispatch);
  }
};

export const HandleLogOut = (dispatch: Dispatch<any>) => {
  UserLogOut(dispatch);
};

export const HandleReturn = (
  state: UserStateInterface,
  dispatch: Dispatch<any>
) => {
  let updatedUser: UserInterface = { ...state.user! };

  if (
    state.user?.openProject &&
    !state.user?.openFolder &&
    !state.user?.openReport
  ) {
    updatedUser.openProject = null;
    dispatch({
      type: "UPDATE_USER",
      payload: updatedUser,
      response: "",
    });
  } else if (state.user?.openFolder) {
    updatedUser.openFolder = FindFolderByID(
      state.user?.openProject?.projectFolders,
      state.user?.openFolder.folderParentID
    );
    dispatch({
      type: "UPDATE_USER",
      payload: updatedUser,
      response: "",
    });
  } else if (state.user?.openReport) {
    updatedUser.openReport = null;
    dispatch({
      type: "UPDATE_USER",
      payload: updatedUser,
      response: "",
    });
    dispatch({ type: "CLOSE_REPORT" });
  }

  // PING SERVER TO UPDATE THE SESSION
  PingServerWithLastActionDate(updatedUser);
};

export const OpenImageInNewWindow = (src: string) => {
  window.open(src, "_blank");
};

export function OpenPageInNewWindow(
  destinationPage: string,
  data: object = {}
) {
  const reportPageUrl = `${
    window.location.origin
  }/#/${destinationPage}?data=${encodeURIComponent(JSON.stringify(data))}`;
  window.open(reportPageUrl, "_blank");
}

export const StopEventPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const PingServerWithLastActionDate = async (
  user: UserInterface,
  maxRetries: number = 4,
  delay: number = 1000
): Promise<ServerResponseInterface | string> => {
  let serverResponse;

  if (!user) {
    return "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
  }

  const userLastActionData: UserLastActionInterface = {
    lastActionDate: user.lastActionDate,
    ...(user.openProject != null && {
      openProject: user.openProject.projectID,
    }),
    ...(user.openFolder != null && { openFolder: user.openFolder.folderID }),
    ...(user.openModel != null && { openModel: user.openModel }),
    ...(user.openReport != null && { openReport: user.openReport }),
    type: "UserLastActionInterface",
  };

  const url = "./php/server/session-updater-proxy.php";

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      const response = await axios.post(
        url,
        { userLastActionData },
        {
          headers: {
            "Content-Type": "application/json",
            timeout: 5000,
          },
        }
      );
      serverResponse = response.data as ServerResponseInterface;
      return serverResponse;
    } catch (error: any) {
      console.error(
        `Attempt ${attempt} failed:`,
        error.message || error.response?.data
      );
      if (attempt < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        serverResponse = error.message || "Failed after multiple attempts.";
      }
    }
  }

  return serverResponse;
};

export function ClearIntervalID() {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
  }
}
