import { FC, Dispatch, useEffect, useState } from "react";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";

interface userSubscriptionDialogProps {
  reveal: boolean;
  user: UserInterface;
  setSubscriptionDialogReveal: Dispatch<any>;
  dispatch: Dispatch<any>;
}

const UserSubscriptionDialog: FC<userSubscriptionDialogProps> = ({
  reveal,
  user,
  setSubscriptionDialogReveal,
  dispatch,
}) => {
  const [submit, setSubmit] = useState(false);
  const [cancel, setCancel] = useState(false);

  useEffect(() => {
    if (cancel) {
      setCancel(false);
      setSubscriptionDialogReveal(false);
    }
  }, [cancel]);
  useEffect(() => {
    if (submit) {
      setSubmit(false);
    }
  }, [submit]);

  return (
    <>
      {(() => {
        if (reveal) {
          return (
            <div className="dialogCover">
              <div id="profileDialog">
                <h2 className="dialogTitle">SUBSCRIPTION</h2>
                <div className="dialogValueWrapper">
                  <span className="dialogMessage">User Name:</span>
                  <input
                    type="text"
                    name="userName"
                    placeholder={user.userName}
                    onChange={(e) => {}}
                  />
                </div>
                <div className="dialogValueWrapper">
                  <span className="dialogMessage">Email:</span>
                  <input
                    type="text"
                    name="newEmail"
                    placeholder={user.email}
                    onChange={(e) => {}}
                  />
                </div>
                <div className="dialogValueWrapper">
                  <span className="dialogMessage">Company:</span>
                  <input
                    type="text"
                    name="company"
                    placeholder={user.company}
                    onChange={(e) => {}}
                  />
                </div>
                <div className="dialogValueWrapper">
                  <span className="dialogMessage">Account Status:</span>
                  <input
                    type="text"
                    name="accountStatus"
                    value={"user.accountStatus"}
                    readOnly
                  />
                </div>
                <div className="dialogValueWrapper">
                  <span className="dialogMessage">New Password:</span>
                  <input
                    type="password"
                    name="newPassword"
                    placeholder={"New Password"}
                    onChange={(e) => {}}
                  />
                </div>
                <div className="dialogValueWrapper">
                  <span className="dialogMessage">Confirm New Password:</span>
                  <input
                    type="password"
                    name="confirmNewPassword"
                    placeholder={"Confirm New Password"}
                    onChange={(e) => {}}
                  />
                </div>
                <div className="dialogButtonWrapper">
                  <input
                    type="button"
                    name="cancel"
                    value="Cancel"
                    onClick={() => setCancel(true)}
                  />
                  <input
                    type="button"
                    name="submit"
                    value="Submit"
                    onClick={() => setSubmit(true)}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default UserSubscriptionDialog;
