import axios from "axios";
import { UserStateInterface } from "../../../middleware/interfaces/user/user-state-interface";
import { FileInterface } from "../../../middleware/interfaces/file/file-interface";

export const ModelRetrieve = {
  Retrieve: async (state: UserStateInterface) => {
    let serverResponse;
    if (state.data) {
      const modelRetrieve: FileInterface = state.data;
      const url = "./php/file/model-retriever-proxy.php";
      await axios
        .post(url, modelRetrieve)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = {
            status: "error",
            header: "error",
            message: error.message,
          };
        });
    } else {
      serverResponse = {
        status: "error",
        header: "error",
        message: "Something went wrong! Please contact DigitalCharcoal",
      };
    }

    return serverResponse;
  },
};
