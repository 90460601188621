import { FC, useEffect } from "react";
import {
  CheckAuthentication,
  UpdateUserLastActionDate,
} from "../../helper/ui/methods";
import { useAppStateContext } from "../../middleware/context/state-context-provider";
import MainNavigationPanel from "../ui/user/main-navigation-panel";
import MainFooterPanel from "../ui/user/main-footer-panel";
import SocialMediaLinks from "../ui/user/social-media-links";

export const ContactUs: FC = () => {
  const [state, dispatch] = useAppStateContext();

  useEffect(() => {
    if (state.user === null || state.user === undefined) {
      console.log("CHECKING AUTHENTICATION!");
      CheckAuthentication(dispatch);
    }
  }, []);

  return (
    <>
      {(() => {
        return (
          <div
            id="pageWrapper"
            onClick={() => UpdateUserLastActionDate(state.user!, dispatch)}
          >
            <MainNavigationPanel user={state.user} />
            <div id="contentWrapper">
              <div id="content">
                <div id="informationCanvas">
                  <h1>Contact Us</h1>
                  <p>
                    We're here to help! Reach out to us through any of the
                    following methods. We look forward to hearing from you and
                    we aim to respond to all inquiries in a timely manner.
                  </p>
                  <h3>Phone:</h3>
                  <p>This service is not available currently.</p>
                  <h3>Email:</h3>
                  <p>
                    For service inquiries, please contact:{" "}
                    <a href="mailto:Support@digitalcharcoal.io">
                      Support@digitalcharcoal.io
                    </a>
                    <br />
                    For general purposes, please contact:{" "}
                    <a href="mailto:Digicharcoal@gmail.com">
                      Digicharcoal@gmail.com
                    </a>
                  </p>
                  <h3>Follow Us:</h3>
                  <SocialMediaLinks />
                </div>
              </div>
            </div>
            <MainFooterPanel />
          </div>
        );
      })()}
    </>
  );
};
