import { useState, useEffect, Dispatch } from "react";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";
import { FormatDateBE, FormatDateFE } from "../../../helper/general/methods";
import { ProjectInterface } from "../../../middleware/interfaces/project/project-interface";
import { UserDecisionInterface } from "../../../middleware/interfaces/user/user-decision-interface";

interface projectDialogProps {
  reveal: string;
  user: UserInterface;
  selectedProject: ProjectInterface | undefined | null;
  setProjectDialogReveal: Dispatch<React.SetStateAction<string>>;
  dispatch: Dispatch<any>;
}

const ProjectDialog: React.FC<projectDialogProps> = ({
  reveal,
  user,
  selectedProject,
  setProjectDialogReveal,
  dispatch,
}) => {
  const [projectData, setProjectData] = useState({
    projectName: "",
    projectLocation: "",
    projectStartDate: "",
    projectEndDate: "",
    projectDescription: "",
  });

  const HandleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setProjectData((prev) => ({ ...prev, [name]: value }));
  };

  const CreateProject = () => {
    setProjectDialogReveal("");
    const newProject: ProjectInterface = {
      ...projectData,
      projectUserRole: "OWNER",
      email: user.email,
      projectCreationDate: FormatDateBE(new Date()),
      projectFiles: [],
      projectFolders: [],
      projectReports: [],
      type: "ProjectInterface",
    };
    dispatch({ type: "CREATE_PROJECT", payload: newProject });
  };

  const EditProject = () => {
    setProjectDialogReveal("");
    const decision: UserDecisionInterface = {
      title: "Edit Project",
      message:
        "You have selected a project to edit. Are you sure you want to continue?",
      decisionOptions: ["EDIT_PROJECT", "NO_ACTION"],
      result: "",
      data: {
        email: user.email,
        projectUserRole: selectedProject?.projectUserRole!,
        projectID: selectedProject?.projectID!,
        projectName: projectData.projectName,
        projectLocation: projectData.projectLocation,
        projectDescription: projectData.projectDescription,
        projectStartDate: projectData.projectStartDate,
        projectEndDate: projectData.projectEndDate,
        projectEditDate: FormatDateBE(new Date()),
        type: "ProjectEditInterface",
      },
      type: "UserDecisionInterface",
    };
    dispatch({ type: "DECISION", decision: decision });
  };

  useEffect(() => {
    if (reveal === "") {
      setProjectData({
        projectName: "",
        projectLocation: "",
        projectStartDate: "",
        projectEndDate: "",
        projectDescription: "",
      });
    } else if (reveal === "EDIT") {
      setProjectData({
        projectName: selectedProject?.projectName!,
        projectLocation: selectedProject?.projectLocation ?? "",
        projectStartDate: selectedProject?.projectStartDate ?? "",
        projectEndDate: selectedProject?.projectEndDate ?? "",
        projectDescription: selectedProject?.projectDescription ?? "",
      });
    }
  }, [reveal]);

  if (reveal === "CREATE") {
    return (
      <div className="dialogCover">
        <div id="projectDialog">
          <h2 className="dialogTitle">CREATE PROJECT</h2>
          <div className="dialogValueWrapper">
            <span className="dialogInputLabel">Name:</span>
            <input
              type="text"
              name="projectName"
              placeholder="Project Name"
              value={projectData.projectName}
              onChange={HandleInputChange}
            />
          </div>
          <div className="dialogValueWrapper">
            <span className="dialogInputLabel">Location:</span>
            <input
              type="text"
              name="projectLocation"
              placeholder="Project Location"
              value={projectData.projectLocation}
              onChange={HandleInputChange}
            />
          </div>
          <div className="dialogValueWrapper">
            <span className="dialogInputLabel">Start Date:</span>
            <input
              type="date"
              name="projectStartDate"
              value={projectData.projectStartDate}
              onChange={HandleInputChange}
            />
          </div>
          <div className="dialogValueWrapper">
            <span className="dialogInputLabel">End Date:</span>
            <input
              type="date"
              name="projectEndDate"
              value={projectData.projectEndDate}
              onChange={HandleInputChange}
            />
          </div>
          <div className="dialogTextInputWrapper">
            <textarea
              name="projectDescription"
              placeholder="Project description..."
              value={projectData.projectDescription}
              onChange={HandleInputChange}
            />
          </div>
          <div className="dialogButtonWrapper">
            <input
              type="button"
              name="cancel"
              value="Cancel"
              onClick={() => setProjectDialogReveal("")}
            />
            <input
              type="button"
              name="create"
              value="Create"
              onClick={CreateProject}
            />
          </div>
        </div>
      </div>
    );
  } else if (reveal === "EDIT") {
    return (
      <div className="dialogCover">
        <div id="projectDialog">
          <h2 className="dialogTitle">EDIT PROJECT</h2>
          <div className="dialogValueWrapper">
            <span className="dialogInputLabel">Name:</span>
            <input
              type="text"
              name="projectName"
              placeholder={projectData.projectName}
              value={projectData.projectName}
              onChange={HandleInputChange}
            />
          </div>
          <div className="dialogValueWrapper">
            <span className="dialogInputLabel">Location:</span>
            <input
              type="text"
              name="projectLocation"
              placeholder={projectData.projectLocation}
              value={projectData.projectLocation}
              onChange={HandleInputChange}
            />
          </div>
          <div className="dialogValueWrapper">
            <span className="dialogInputLabel">Start Date:</span>
            <input
              type="date"
              name="projectStartDate"
              value={
                !isNaN(new Date(projectData.projectStartDate).getTime())
                  ? FormatDateFE(projectData.projectStartDate)
                  : ""
              }
              onChange={HandleInputChange}
            />
          </div>
          <div className="dialogValueWrapper">
            <span className="dialogInputLabel">End Date:</span>
            <input
              type="date"
              name="projectEndDate"
              value={
                !isNaN(new Date(projectData.projectEndDate).getTime())
                  ? FormatDateFE(projectData.projectEndDate)
                  : ""
              }
              onChange={HandleInputChange}
            />
          </div>
          <div className="dialogTextInputWrapper">
            <textarea
              name="projectDescription"
              placeholder="Project description..."
              value={projectData.projectDescription}
              onChange={HandleInputChange}
            />
          </div>
          <div className="dialogButtonWrapper">
            <input
              type="button"
              name="cancel"
              value="Cancel"
              onClick={() => setProjectDialogReveal("")}
            />
            <input
              type="button"
              name="edit"
              value="Edit"
              onClick={EditProject}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ProjectDialog;
