import { ReportElementNoteInterface } from "../../middleware/interfaces/report/report-element-note-interface";
import { ReportElementNoteDeleteInterface } from "../../middleware/interfaces/report/report-element-note-delete-interface";
import { ReportElementNoteEditInterface } from "../../middleware/interfaces/report/report-element-note-edit-interface";
import { ReportInterface } from "../../middleware/interfaces/report/report-interface";
import { ServerResponseInterface } from "../../middleware/interfaces/server/server-response";

export const CreateReportFromServerResponse = (
  serverResponseObject: ServerResponseInterface
) => {
  const report: ReportInterface = serverResponseObject.data! as ReportInterface;
  report.reportElements?.forEach((reportElement) => {
    if (!reportElement.reportElementGroupName) {
      console.warn(
        "reportElementGroupName is null or undefined",
        reportElement.reportElementName
      );
      return;
    }

    if (!report.reportContentFilter) {
      report.reportContentFilter = {
        reportElementGroupData: [],
        type: "ReportContentFilterInterface",
      };
    }

    const existingGroup =
      report.reportContentFilter.reportElementGroupData.find(
        (element) =>
          element.reportElementGroupName ===
          reportElement.reportElementGroupName
      );

    if (existingGroup) {
      existingGroup.reportElementGroupData.reportElementGroupCount += 1;
    } else {
      report.reportContentFilter.reportElementGroupData.push({
        reportElementGroupName: reportElement.reportElementGroupName,
        reportElementGroupData: {
          reportElementGroupCount: 1,
          reportElementGroupFilter: true,
          reportElementGroupColor: reportElement.reportElementColor,
        },
      });
    }
  });

  return report;
};

export const UpdateReportWithServerResponse = (
  report: ReportInterface,
  serverResponseObjectData: object
) => {
  if (IsReportElementNoteInterface(serverResponseObjectData)) {
    const reportElementNote: ReportElementNoteInterface =
      serverResponseObjectData as ReportElementNoteInterface;
    if (report.reportElements) {
      const reportElement = report.reportElements.find(
        (element) =>
          element.reportElementID === reportElementNote.reportElementID
      );

      if (reportElement) {
        if (!reportElement.reportElementNotes) {
          reportElement.reportElementNotes = [];
        }
        reportElement.reportElementNotes.push(reportElementNote);
      }
    }
  } else if (IsReportElementNoteDeleteInterface(serverResponseObjectData)) {
    const reportElementNoteDeleteData: ReportElementNoteDeleteInterface =
      serverResponseObjectData as ReportElementNoteDeleteInterface;
    if (report.reportElements) {
      const reportElement = report.reportElements.find(
        (reportElement) =>
          reportElement.reportElementID ===
          reportElementNoteDeleteData.reportElementID
      );
      if (reportElement) {
        reportElement.reportElementNotes =
          reportElement.reportElementNotes.filter(
            (reportElementNote) =>
              reportElementNote.reportElementNoteID !==
              reportElementNoteDeleteData.reportElementNoteID
          );
      }
    }
  } else if (IsReportElementNoteEditInterface(serverResponseObjectData)) {
    const reportElementNoteEditData: ReportElementNoteEditInterface =
      serverResponseObjectData as ReportElementNoteEditInterface;
    if (report.reportElements) {
      const reportElement = report.reportElements.find(
        (reportElement) =>
          reportElement.reportElementID ===
          reportElementNoteEditData.reportElementID
      );
      if (reportElement && reportElement.reportElementNotes) {
        const reportElementNote = reportElement.reportElementNotes.find(
          (reportElementNote) =>
            reportElementNote.reportElementNoteID ===
            reportElementNoteEditData.reportElementNoteID
        );
        if (reportElementNote) {
          reportElementNote.reportElementNote =
            reportElementNoteEditData.reportElementNote;
          reportElementNote.reportElementNoteEditDate =
            reportElementNoteEditData.reportElementNoteEditDate;
        }
      }
    }
  }

  return report;
};

function IsReportElementNoteInterface(
  response: object
): response is ReportElementNoteInterface {
  return (
    (response as ReportElementNoteInterface).type ===
    "ReportElementNoteInterface"
  );
}

function IsReportElementNoteEditInterface(
  response: object
): response is ReportElementNoteEditInterface {
  return (
    (response as ReportElementNoteEditInterface).type ===
    "ReportElementNoteEditInterface"
  );
}

function IsReportElementNoteDeleteInterface(
  response: object
): response is ReportElementNoteDeleteInterface {
  return (
    (response as ReportElementNoteDeleteInterface).type ===
    "ReportElementNoteDeleteInterface"
  );
}
