import {
  FC,
  PropsWithChildren,
  useReducer,
  createContext,
  useContext,
} from "react";
import { reducer } from "../handlers/state-handler";
import {
  initialState,
  UserStateInterface,
} from "../interfaces/user/user-state-interface";
import { Action } from "./actions";
import { CoreHandler } from "../handlers/core-handler";

const appStateContext = createContext<
  [UserStateInterface, React.Dispatch<Action>]
>([initialState, () => {}]);

export const StateContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useReducer(reducer, initialState);

  const dispatch = (value: Action) => {
    setState(value);
  };

  return (
    <appStateContext.Provider value={[state, dispatch]}>
      <CoreHandler />
      {children}
    </appStateContext.Provider>
  );
};

export const useAppStateContext = () => {
  return useContext(appStateContext);
};
