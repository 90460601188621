import axios from "axios";
import { UserStateInterface } from "../../../middleware/interfaces/user/user-state-interface";
import { ReportDescriptionUploadInterface } from "../../../middleware/interfaces/report/report-description-upload-interface";

export const ReportDescriptionUpload = {
  Upload: async (state: UserStateInterface) => {
    let serverResponse;
    if (state.data) {
      const reportDescriptionUploadData: ReportDescriptionUploadInterface =
        state.data;
      const url = "./php/report/report-description-uploader-proxy.php";
      await axios
        .post(url, reportDescriptionUploadData)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = {
            status: "error",
            header: "error",
            message: error.message,
          };
        });
    } else {
      serverResponse = {
        status: "error",
        header: "error",
        message: "Something went wrong! Please contact DigitalCharcoal",
      };
    }
    return serverResponse;
  },
};
