import { FC, useEffect } from "react";
import {
  CheckAuthentication,
  UpdateUserLastActionDate,
} from "../../helper/ui/methods";
import { useAppStateContext } from "../../middleware/context/state-context-provider";
import MainNavigationPanel from "../ui/user/main-navigation-panel";
import MainFooterPanel from "../ui/user/main-footer-panel";
import { useNavigate } from "react-router-dom";
import "../../styles/products-page.css";

export const Products: FC = () => {
  const [state, dispatch] = useAppStateContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.user === null || state.user === undefined) {
      console.log("CHECKING AUTHENTICATION!");
      CheckAuthentication(dispatch);
    }
  }, []);

  return (
    <>
      {(() => {
        return (
          <div
            id="pageWrapper"
            onClick={() => UpdateUserLastActionDate(state.user!, dispatch)}
          >
            <MainNavigationPanel user={state.user} />
            <div id="contentWrapper">
              <div id="content">
                <div id="productsTableOfContent">
                  <div className="product">
                    <img
                      className="icon-big"
                      src="assets/thumbs/products/mousePlugin.jpg"
                      alt="MousePlugin"
                    />
                    <p>
                      Mouse is a pathfinding plugin for Grasshopper based on the
                      Theta* algorithm...
                    </p>
                  </div>
                  <div
                    className="product"
                    onClick={() => {
                      navigate("/products/ncrp");
                    }}
                  >
                    <img
                      className="icon-big"
                      src="assets/thumbs/products/ncrp.png"
                      alt="NavisCustomReportPro"
                    />
                    <p>
                      "NavisCustomReportPro" is an Autodesk Navisworks plugin...
                    </p>
                  </div>
                  <div className="product">
                    <img
                      className="icon-big"
                      src="assets/thumbs/products/stackingProgramming.jpg"
                      alt="StackingProgramming"
                    />
                    <p>
                      "Stacking Programming" is a Windows application
                      designed...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <MainFooterPanel />
          </div>
        );
      })()}
    </>
  );
};
