import { ReportDescriptionInterface } from "../report/report-description-interface";
import { ReportInterface } from "../report/report-interface";
import { UserDecisionInterface } from "./user-decision-interface";
import { UserInterface } from "./user-interface";

export interface UserStateInterface {
  user: UserInterface | null;
  data?: any;
  response?: any;
  openModel?: any;
  openReport?: ReportInterface | null;
  openReportDescription?: ReportDescriptionInterface | null;
  decision?: UserDecisionInterface | null;
  type: "UserStateInterface";
}

export const initialState: UserStateInterface = {
  user: null,
  type: "UserStateInterface",
};
