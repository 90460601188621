import axios from "axios";
import { UserStateInterface } from "../../../middleware/interfaces/user/user-state-interface";
import { ReportDeleteInterface } from "../../../middleware/interfaces/report/report-delete-interface";

export const ReportDelete = {
  Delete: async (state: UserStateInterface) => {
    let serverResponse;
    if (state.data) {
      const reportDeleteData: ReportDeleteInterface = state.data;
      const url = "./php/common/deleter-proxy.php";
      await axios
        .post(url, reportDeleteData)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = {
            status: "error",
            header: "error",
            message: error.message,
          };
        });
    } else {
      serverResponse = {
        status: "error",
        header: "error",
        message: "Something went wrong! Please contact DigitalCharcoal",
      };
    }
    return serverResponse;
  },
};
