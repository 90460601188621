import { FC, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import {
  CheckAuthentication,
  HandleInputChange,
} from "../../helper/ui/methods";
import { useAppStateContext } from "../../middleware/context/state-context-provider";
import { UserAuthenticationInterface } from "../../middleware/interfaces/user/user-authentication-interface";
import "../../styles/authentication-registeration.css";
import UserRegistrationForm from "../ui/user/user-registration-form";
import ResponseDialog from "../ui/user/response-dialog";
import UserPasswordRecoverDialog from "../ui/user/user-password-recover-dialog";
import MainNavigationPanel from "../ui/user/main-navigation-panel";
import MainFooterPanel from "../ui/user/main-footer-panel";

export const AuthenticationAndRegisteration: FC = () => {
  const [state, dispatch] = useAppStateContext();
  const [authenticationData, setAuthenticationData] =
    useState<UserAuthenticationInterface>({
      email: "",
      password: "",
      loginTime: null,
      type: "UserAuthenticationWebInterface",
    });
  const [registrationFormReveal, setRegistrationFormReveal] = useState(false);
  const [recoverPasswordDialogReveal, setRecoverPasswordDialogReveal] =
    useState(false);

  useEffect(() => {
    if (state.user === null || state.user === undefined) {
      CheckAuthentication(dispatch);
    }
  }, []);

  useEffect(() => {
    if (typeof state.response === "string") {
      if (state.response !== "") {
        setRegistrationFormReveal(false);
      }
    }
  }, [state.response]);

  const onSignIn = (authenticationData: UserAuthenticationInterface) => {
    authenticationData.loginTime = new Date();
    dispatch({ type: "AUTHENTICATE", payload: authenticationData });
  };

  const onSignUp = () => {
    // dispatch({
    //   type: "RESPONSE",
    //   response: "",
    // });
    setRegistrationFormReveal(!registrationFormReveal);
  };

  return (
    <>
      {(() => {
        if (state.user !== null && state.user !== undefined) {
          return <Navigate to="/dashboard" />;
        } else {
          return (
            <div id="pageWrapper">
              <MainNavigationPanel user={state.user} />
              <div id="contentWrapper">
                <div id="content">
                  <div id="authenticationWrapper">
                    <form id="authentication-form">
                      <input
                        id="authentication-user"
                        type="text"
                        name="email"
                        placeholder="Email"
                        onChange={(e) => {
                          HandleInputChange(e, setAuthenticationData);
                        }}
                      ></input>
                      <input
                        id="authentication-password"
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={(e) => {
                          HandleInputChange(e, setAuthenticationData);
                        }}
                      ></input>
                      <input
                        id="register-button"
                        type="button"
                        name="signUp"
                        value="Sign Up"
                        onClick={() => {
                          onSignUp();
                        }}
                      ></input>
                      <input
                        id="authentication-button"
                        type="button"
                        name="signIn"
                        value="Sign In"
                        onClick={() => {
                          onSignIn(authenticationData);
                        }}
                      ></input>
                    </form>
                    <div id="forgot-credentials">
                      <span
                        onClick={() => {
                          setRecoverPasswordDialogReveal(true);
                        }}
                      >
                        Forgot password?
                      </span>
                    </div>
                  </div>
                  <UserPasswordRecoverDialog
                    reveal={recoverPasswordDialogReveal}
                    setRecoverPasswordDialogReveal={
                      setRecoverPasswordDialogReveal
                    }
                    dispatch={dispatch}
                  />
                  <UserRegistrationForm
                    reveal={registrationFormReveal}
                    setRegistrationFormReveal={setRegistrationFormReveal}
                    dispatch={dispatch}
                  />
                  <ResponseDialog
                    title="Server Response"
                    message={state.response}
                    dispatch={dispatch}
                  />
                </div>
              </div>
              <MainFooterPanel />
            </div>
          );
        }
      })()}
    </>
  );
};
