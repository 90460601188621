import axios from "axios";
import { UserStateInterface } from "../../../middleware/interfaces/user/user-state-interface";

export const FolderDownload = {
  Download: async (state: UserStateInterface) => {
    return new Promise(async (resolve, reject) => {
      // Wrap the entire logic in a new Promise
      let serverResponse;
      if (state.data) {
        const folderDownloadData = state.data;
        const url = "./php/common/downloader-proxy.php";
        axios
          .post(url, folderDownloadData, {
            responseType: "blob", // Start by assuming a binary response
          })
          .then(async (response) => {
            const contentType = response.headers["content-type"];
            if (contentType.includes("application/zip")) {
              // Handle as file download
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;

              const contentDisposition =
                response.headers["content-disposition"];
              let fileName = "download.zip"; // A default filename if none is specified
              if (contentDisposition) {
                const fileNameMatch =
                  contentDisposition.match(/filename="?(.*?)"?$/);
                if (fileNameMatch && fileNameMatch.length === 2)
                  fileName = fileNameMatch[1];
              }
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
              window.URL.revokeObjectURL(url);

              serverResponse = "";
              resolve(serverResponse);
            } else {
              serverResponse = JSON.parse(await response.data.text());
              resolve(serverResponse);
            }
          })
          .catch((error) => {
            console.error("Error:", error.message);
            serverResponse = {
              status: "error",
              header: "error",
              message: error.message,
            };
            reject(serverResponse);
          });
      } else {
        serverResponse = {
          status: "error",
          header: "error",
          message: "Something went wrong! Please contact DigitalCharcoal",
        };
        resolve(serverResponse);
      }
    });
  },
};
