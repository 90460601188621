import axios from "axios";
import { UserStateInterface } from "../../../middleware/interfaces/user/user-state-interface";
import { UserRegistrationInterface } from "../../../middleware/interfaces/user/user-registration-interface";

export const UserRegistration = {
  Register: async (state: UserStateInterface) => {
    let serverResponse;
    if (state.data) {
      const registrationData: UserRegistrationInterface = state.data;
      const url = "./php/user/user-registrator-proxy.php";
      await axios
        .post(url, registrationData)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = error.message;
        });
    } else {
      serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
    }

    return serverResponse;
  },
};
