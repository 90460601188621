import axios from "axios";
import { UserStateInterface } from "../../../middleware/interfaces/user/user-state-interface";
import { FolderCreateInterface } from "../../../middleware/interfaces/folder/folder-create-interface";

export const FolderCreate = {
  Create: async (state: UserStateInterface) => {
    let serverResponse;
    if (state.data) {
      const folderCreateData: FolderCreateInterface = state.data;
      const url = "./php/folder/folder-creator-proxy.php";
      await axios
        .post(url, folderCreateData)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = {
            status: "error",
            header: "error",
            message: error.message,
          };
        });
    } else {
      serverResponse = {
        status: "error",
        header: "error",
        message: "Something went wrong! Please contact DigitalCharcoal",
      };
    }
    return serverResponse;
  },
};
