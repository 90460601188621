import axios from "axios";
import { UserStateInterface } from "../../../middleware/interfaces/user/user-state-interface";
import { UserLogOutInterface } from "../../../middleware/interfaces/user/user-logout-interface";

export const UserLogOut = {
  LogOut: async (state: UserStateInterface) => {
    let serverResponse;
    if (state.data) {
      const userLogOutData: UserLogOutInterface = state.data;
      const url = "./php/user/user-logouter-proxy.php";
      await axios
        .post(url, userLogOutData)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = error.message;
        });
    } else {
      serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
    }

    return serverResponse;
  },
};
