import { FC, Dispatch, useEffect, useState } from "react";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";
import { UserDecisionInterface } from "../../../middleware/interfaces/user/user-decision-interface";
import { HandleInputChange } from "../../../helper/ui/methods";
import { UserProfileEditInterface } from "../../../middleware/interfaces/user/user-profile-edit-interface";

interface userProfileDialogProps {
  reveal: boolean;
  user: UserInterface;
  setProfileDialogReveal: Dispatch<any>;
  dispatch: Dispatch<any>;
}

const UserProfileDialog: FC<userProfileDialogProps> = ({
  reveal,
  user,
  setProfileDialogReveal,
  dispatch,
}) => {
  const [submit, setSubmit] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [editProfileData, setEditProfileData] =
    useState<UserProfileEditInterface>({
      userName: user.userName,
      currentEmail: user.email,
      newEmail: user.email,
      company: user.company,
      newPassword: "",
      confirmNewPassword: "",
      type: "UserProfileEditInterface",
    });
  useEffect(() => {
    if (cancel) {
      setCancel(false);
      setProfileDialogReveal(false);
      setEditProfileData({
        userName: user.userName,
        currentEmail: user.email,
        newEmail: user.email,
        company: user.company,
        newPassword: "",
        confirmNewPassword: "",
        type: "UserProfileEditInterface",
      });
    }
  }, [cancel]);
  useEffect(() => {
    if (submit) {
      if (
        user.userName !== editProfileData.userName ||
        user.email !== editProfileData.newEmail ||
        user.company !== editProfileData.company ||
        "" !== editProfileData.newPassword
      ) {
        const decisionMessage =
          `Information to change: ${
            user.userName !== editProfileData.userName ? `"User Name"` : ""
          }
            ${user.email !== editProfileData.newEmail ? `"Email"` : ""}
            ${user.company !== editProfileData.company ? `"Company"` : ""}
            ${"" !== editProfileData.newPassword ? `"Password"` : ""}` +
          ". " +
          "Do you wish to continue?";

        const decision: UserDecisionInterface = {
          title: "Edit Profile",
          message: decisionMessage,
          decisionOptions: ["EDIT_PROFILE", "NO_ACTION"],
          result: "",
          data: editProfileData,
          type: "UserDecisionInterface",
        };
        setProfileDialogReveal(false);
        dispatch({
          type: "DECISION",
          decision: decision,
        });
        setEditProfileData({
          userName: user.userName,
          currentEmail: user.email,
          newEmail: user.email,
          company: user.company,
          newPassword: "",
          confirmNewPassword: "",
          type: "UserProfileEditInterface",
        });
      } else {
        setProfileDialogReveal(false);
        dispatch({
          type: "RESPONSE",
          response: "No change has been made!",
        });
      }
    }
    setSubmit(false);
  }, [submit]);

  return (
    <>
      {(() => {
        if (reveal) {
          return (
            <div className="dialogCover">
              <div id="profileDialog">
                <h2 className="dialogTitle">PROFILE</h2>
                <div className="dialogValueWrapper">
                  <span className="dialogMessage">User Name:</span>
                  <input
                    type="text"
                    name="userName"
                    placeholder={user.userName}
                    onChange={(e) => {
                      HandleInputChange(e, setEditProfileData);
                    }}
                  />
                </div>
                <div className="dialogValueWrapper">
                  <span className="dialogMessage">Email:</span>
                  <input
                    type="text"
                    name="newEmail"
                    placeholder={user.email}
                    onChange={(e) => {
                      HandleInputChange(e, setEditProfileData);
                    }}
                  />
                </div>
                <div className="dialogValueWrapper">
                  <span className="dialogMessage">Company:</span>
                  <input
                    type="text"
                    name="company"
                    placeholder={user.company}
                    onChange={(e) => {
                      HandleInputChange(e, setEditProfileData);
                    }}
                  />
                </div>
                <div className="dialogValueWrapper">
                  <span className="dialogMessage">New Password:</span>
                  <input
                    type="password"
                    name="newPassword"
                    placeholder={"New Password"}
                    onChange={(e) => {
                      HandleInputChange(e, setEditProfileData);
                    }}
                  />
                </div>
                <div className="dialogValueWrapper">
                  <span className="dialogMessage">Confirm New Password:</span>
                  <input
                    type="password"
                    name="confirmNewPassword"
                    placeholder={"Confirm New Password"}
                    onChange={(e) => {
                      HandleInputChange(e, setEditProfileData);
                    }}
                  />
                </div>
                <div className="dialogButtonWrapper">
                  <input
                    type="button"
                    name="cancel"
                    value="Cancel"
                    onClick={() => setCancel(true)}
                  />
                  <input
                    type="button"
                    name="submit"
                    value="Submit"
                    onClick={() => setSubmit(true)}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default UserProfileDialog;
